import React from "react";

function TopBar() {
  return (
    <div className="container-fluid bg-dark px-5 d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          >
            {/* <small className="me-3 text-light">
              <i className="fa fa-map-marker-alt me-2"></i>Behind ajwa bakers, grand trunk road, next to general electronics. Lalamusa, Gujrat, Pakistan.
              USA
            </small> */}
            <small className="me-3 text-light">
              <i className="fa fa-phone-alt me-2"></i>+92-323-0002900
            </small>
            <small className="text-light">
              <i className="fa fa-envelope-open me-2"></i><a href="https://mail.google.com/mail/u/0/#inbox">fd.figmadev@gmail.com</a>
            </small>
          </div>
        </div>
        <div className="col-lg-4 text-center text-lg-end">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: "45px" }}
          >
            {/* <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2">
              <i className="fab fa-twitter fw-normal"></i>
            </button> */}

            {/* <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"> */}
            <a
              href="https://www.facebook.com/FigmaDev/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            >
              <i className="fab fa-facebook-f fw-normal"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/figam-dev-0a99032a0/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            >
              <i className="fab fa-linkedin-in fw-normal"></i>
            </a>
            {/* </button> */}

            {/* <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2">
              <i className="fab fa-linkedin-in fw-normal"></i>
            </button> */}

            <a
              href="https://www.instagram.com/figma_dev"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            >
              <i className="fab fa-instagram fw-normal"></i>
            </a>

            {/* <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle">
              <i className="fab fa-youtube fw-normal"></i>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
