import React from "react";
import { Link } from "react-router-dom";

// Blogs images start
import TopBar from "../Sections/TopBar";
import NavBar from "../Sections/NavBar";
import MyFoter from "../Sections/MyFoter";
import { blogsArray } from "../Sections/Blogsdata";
// Blogs images end

function BlogGrid() {
  function truncateText(text, maxWords) {
    const words = text.split(" ");
    const truncated = words.slice(0, maxWords).join(" ");
    return words.length > maxWords ? `${truncated} ...` : truncated;
  }

  return (
    <div>
      <TopBar />

      {/* <!-- Navbar start --> */}
      <div className="container-fluid position-relative p-0">
        <NavBar />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Blog Grid
              </h1>
              {/* <Link href="" className="h5 text-white">
                Home
              </Link>
              <i className="far fa-circle text-white px-2"></i>
              <Link href="" className="h5 text-white">
                Blog Grid
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Navbar end --> */}

      {/* Full Screen Search */}
      <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Full Screen Search End */}

      {/* <!-- Blog start  --> */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            {/* <!-- Blog list Start --> */}

            {blogsArray?.map((i) => {
              return (
                <div
                  className="col-md-4 wow slideInUp"
                  data-wow-delay="0.1s"
                  key={`blog-${i.id}`}
                >
                  <div className="blog-item bg-light rounded overflow-hidden">
                    <div className="blog-img position-relative overflow-hidden">
                      <img className="img-fluid blogs-img-size" src={i.image} alt="" />
                      <div className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                        {i.primaryHeading}
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="d-flex mb-3">
                        <small className="me-3">
                          <i className="far fa-user text-primary me-2"></i>
                          {i.name}
                        </small>
                        <small>
                          <i className="far fa-calendar-alt text-primary me-2"></i>
                          {i.date}
                        </small>
                      </div>
                      <h4 className="mb-3 white-color">{truncateText(i.secondaryHeading, 2)}</h4>
                      <p>{truncateText(i.paragraph1, 10)}</p>
                      <Link
                        className="text-uppercase"
                        to={`/detail/${i.id}`}
                      >
                        Read More <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <!-- Blog list end  --> */}

            {/* <!-- sidebar end  --> */}
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}

      <MyFoter />
    </div>
  );
}

export default BlogGrid;
