import React from 'react'

// testimonial images start
import testimonial1 from "../img/testimonial-1.jpg";
import testimonial2 from "../img/testimonial-2.jpg";
import testimonial3 from "../img/testimonial-3.jpg";
import testimonial4 from "../img/testimonial-4.jpg";
// testimonial images end

// import testimonial2 from "../img/testimonial-2.jpg";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import { Carousel } from 'react-responsive-carousel';

// css file
// import '../main.css';

function Testimonial() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (

    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-4 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
        <h1 className="mb-0">
          What Our Clients Say About Our Digital Services
        </h1>
      </div>
      <div
        className="my-6 testimonial-carousel wow fadeInUp yellow-color"
        data-wow-delay="0.6s"
      >


       <Slider {...settings} >
        
        <div className="testimonial-item bg-light my-4 ">
          <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
    
            <img
              className="img-fluid rounded"
              src={testimonial1}
              style={{ width: "60px", height: "60px" }}
              alt="Client 1"
            />
            <div className="ps-4">
              <h4 className="text-primary mb-1 white-color pt-2">Sarah Thompson</h4>
              <small className="text-uppercase">Marketing Manager</small>
            </div>
          </div>
          <div className="pt-4 pb-5 px-5" >
          Innovative solutions, streamlined processes, and exceptional expertise. Highly recommend Figmadev for cutting-edge marketing technology.
          </div>
        </div>
        <div className="testimonial-item bg-light my-4 ">
          <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
            <img
              className="img-fluid rounded"
              src={testimonial2}
              style={{ width: "60px", height: "60px" }}
              alt="Client 2"
            />
            <div className="ps-4">
              <h4 className="text-primary mb-1 white-color pt-2">John Rodriguez</h4>
              <small className="text-uppercase">Finance Analyst</small>
            </div>
          </div>
          <div className="pt-4 pb-5 px-5">
          Figmadev delivered a robust and scalable solution. Transparent communication and a proactive approach to challenges. 
          </div>
        </div>
        <div className="testimonial-item bg-light my-4">
          <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5 ">
            <img
              className="img-fluid rounded"
              src={testimonial3}
              style={{ width: "60px", height: "60px" }}
              alt="Client 3"
            />
            <div className="ps-4">
              <h4 className="text-primary mb-1 white-color pt-2">Michael Harper </h4>
              <small className="text-uppercase">Finance Analyst</small>
            </div>
          </div>
          <div className="pt-4 pb-5 px-5">
          Figmadev transformed our finance department with time-saving automation and valuable analytics. Their solution is integral our daily operations.
          </div>
        </div>
        <div className="testimonial-item bg-light my-4">
          <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
            <img
              className="img-fluid rounded"
              src={testimonial4}
              style={{ width: "60px", height: "60px" }}
              alt="Client 4"
            />
            <div className="ps-4">
              <h4 className="text-primary mb-1 white-color pt-2">Emily Chen</h4>
              <small className="text-uppercase">Research Scientist
</small>
            </div>
          </div>
          <div className="pt-4 pb-5 px-5">
          Figmade was a wise choice for our IT infrastructure upgrade. Their expertise ensured a seamless transition,and ongoing support. 
          </div>
        </div>
        </Slider>
      </div>
    </div>
  </div>

  )
}

export default Testimonial