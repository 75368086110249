import React from "react";


// team images start
import team1 from "../img/zain-2.jpg";
import team2 from "../img/israr-3.png";
import team3 from "../img/hamza.png";

function Team() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
          <h1 className="mb-0">
            Professional Stuffs Ready to Help Your Business
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
            <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden">
                <img
                  className="img-fluid w-100"
                  src={team1}
                  alt="Team Member 1"
                />
                <div className="team-social">
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  {/* <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </a> */}
               
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              <div className="text-center py-4">
                <h4 className="text-primary white-color">Zain Ul Abidin</h4>
                <p className="text-uppercase m-0 yellow-color"> ceo <br/> Chief Executive Officer </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
            <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden">
                <img
                  className="img-fluid w-100"
                  src={team2}
                  alt="Team Member 2"
                />
                <div className="team-social">
                  <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  {/* <a
                    href="https://www.facebook.com/israr.haq.14/"
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f fw-normal"></i>
                  </a> */}
                  {/* <a
                    href="https://www.instagram.com/israrul9992/"
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <i className="fab fa-instagram fw-normal"></i> 
                    </a> */}
                  <a
                    href="https://www.linkedin.com/in/israr-ul-haq-a395b9197/"
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              <div className="text-center py-4">
                <h4 className="text-primary white-color">Israr Ul Haq</h4>
                <p className="text-uppercase m-0 yellow-color"> coo <br/> Chief Operating Officer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
            <div className="team-item bg-light rounded overflow-hidden">
              <div className="team-img position-relative overflow-hidden">
                <img
                  className="img-fluid w-100"
                  src={team3}
                  alt="Team Member 3"
                />
                <div className="team-social">
                  <a
                    href="https://twitter.com/1808Meru"
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter fw-normal"></i>
                  </a>
                  {/* <a
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    href="https://www.facebook.com/ameer1808/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-square fw-normal"></i>
                  </a> */}
                  {/* <a
                    href="https://www.instagram.com/meru_1808/ "
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram fw-normal"></i>
                  </a> */}
                  <a
                    href="https://www.linkedin.com/in/ameer-hamza-29150b1b4/"
                    className="btn btn-lg btn-primary btn-lg-square rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in fw-normal"></i>
                  </a>
                </div>
              </div>
              <div className="text-center py-4">
                <h4 className="text-primary white-color">Ameer Hamza</h4>
                <p className="text-uppercase m-0 yellow-color"> bd (Lead) <br/> Buisness Developer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
