import React from "react";


// team images end

import NavBar from "../Sections/NavBar";
import TopBar from "../Sections/TopBar";
import MyFoter from "../Sections/MyFoter";
import AboutUs from "../Sections/AboutUs";
import Team from "../Sections/Team";

function About() {
  return (
    <div>
      {/* // <!-- Topbar Start --> */}
      <TopBar />
      {/* // <!-- Topbar End --> */}

      {/* // <!-- Navbar Start --> */}
      <div className="container-fluid position-relative p-0">
        <NavBar />

        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">About Us</h1>
              {/* <Link href="home" className="h5 text-white">
                Home
              </Link>
              <i className="far fa-circle text-white px-2"></i>
              <Link href="about.html" className="h5 text-white">
                About
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Navbar End --> */}

      {/* <!-- Full Screen Search Start --> */}
      <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Full Screen Search End --> */}

      {/* <!-- About Start --> */}
    <AboutUs/>
      {/* <!-- About End --> */}

      {/* <!-- Team Start --> */}
   <Team/>
      {/* <!-- Team End --> */}

    <MyFoter/>
    </div>
  );
}

export default About;
