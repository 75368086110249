import React from "react";
import TopBar from "../Sections/TopBar";
import NavBar from "../Sections/NavBar";
import MyFoter from "../Sections/MyFoter";

function Contact() {
  return (
    <div>
      {/* Spinner Start */}
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner"></div>
          </div> */}
      {/* Spinner End */}

      <TopBar />

      {/* <!-- Navbar & Carousel Start --> */}
      <div className="container-fluid position-relative p-0">
        <NavBar />

        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Contact Us
              </h1>
              {/* <Link href="" className="h5 text-white">
                Home
              </Link>
              <i className="far fa-circle text-white px-2"></i>
              <Link href="" className="h5 text-white">
                Contact
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Navbar & Carousel end --> */}

      {/* contact start  */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 className="mb-0">
              If You Have Any Query, Feel Free To Contact Us
            </h1>
          </div>
          <div className="row g-5 mb-5">
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.1s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+92-323-0002900</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.4s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-envelope-open text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Email to get a free quote</h5>
                  <h4 className="text-primary mb-0">
                    fd.figmadev <br /> @gmail.com
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="d-flex align-items-center wow fadeIn"
                data-wow-delay="0.8s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "155px", height: "60px" }}
                >
                  <i className="fa fa-map-marker-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Visit our office</h5>
                  <h4 className="text-primary mb-0">
                    Figma Dev Grand Trunk Rd lalamusa Punjab 50200,
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5">
            {/* <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
              <form>
                <div className="row g-3">
                  <div className="col-md-6 yellow-color">
                    <input
                      type="text yellow-color"
                      className="form-control border-0 bg-light px-4 yellow-color"
                      placeholder="Your Name"
                      style={{ height: "55px", color: "pink !important" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Your Email"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control border-0 bg-light px-4"
                      placeholder="Subject"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12 yellow-color">
                    <textarea
                      className="form-control border-0 bg-light px-4 py-3 yellow-color text___area_placeholder"
                      rows="4"
                      placeholder="Message"
                      style={{
                        color: "#F2BE10",
                        borderColor: "#F2BE10",
                        backgroundColor: "lightyellow",
                      }}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
            <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
              <iframe
                className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209.84100626765664!2d73.95373832802952!3d32.70050596001146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391f093519c3ab13%3A0x25cb3e2fe06c87d0!2sFigma%20Dev!5e0!3m2!1sen!2s!4v1700906711688!5m2!1sen!2s"
                frameBorder="0"
                style={{ minHeight: "350px", border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* contact end  */}

      <MyFoter />
    </div>
  );
}

export default Contact;
