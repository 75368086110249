import React from 'react'
import { Link } from 'react-router-dom'
import figmalogofive from '../img/figmadev-05.png'

function MyFoter() {
  return (
    <>
    <div
          className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-4 col-md-6 footer-about ">
                <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4 ">
                  <a to="index.html" className="navbar-brand">
                  <img src={figmalogofive} alt="" style={{width : '60px', height:'45px'}} />

                    <h1 className="m-0 text-white">
                    Figma Dev
                    </h1>
                  </a>
                  <p className="mt-3 mb-4">
                  FigmaDev pioneers digital innovation with expert-driven web and app development. Our award-winning team ensures excellence, offering 24/7 support. Call for a free quote and redefine your digital journey with us.
                  </p>
                  <form action="">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control border-white p-3"
                        placeholder="Your Email"
                      />
                      <button className="btn btn-dark">Sign Up</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-8 col-md-6">
                <div className="row gx-5">
                  <div className="col-lg-4 col-md-12 pt-5 mb-5">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 className="text-light mb-0">Get In Touch</h3>
                    </div>
                    <div className="d-flex mb-2">
                      <i className="bi bi-geo-alt text-primary me-2 white-color"></i>
                      <p className="mb-0">Figma Dev, Grand Trunk Rd, behind ajwa bakers, Camping Ground lalamusa Gujrat, Punjab 50200, Pakistan</p>
                    </div>
                    <div className="d-flex mb-2">
                      <i className="bi bi-envelope-open text-primary me-2 white-color"></i>
                      <p className="mb-0">fd.figmadev@gmail.com</p>
                    </div>
                    <div className="d-flex mb-2">
                      <i className="bi bi-telephone text-primary me-2 white-color"></i>
                      <p className="mb-0">+92-323-0002900</p>
                    </div>
                    <div className="d-flex mt-4">
                      {/* <Link
                        className="btn btn-primary btn-square me-2"
                        to="#"
                      >
                        <i className="fab fa-twitter fw-normal white-color"></i>
                      </Link> */}
                      <a
                href="https://www.facebook.com/FigmaDev/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              >
                <i className="fab fa-facebook-f fw-normal"></i>
            </a>
                      {/* <Link
                        className="btn btn-primary btn-square me-2"
                        to="#"
                      >
                        <i className="fab fa-linkedin-in fw-normal white-color"></i>
                      </Link> */}
                    <a
              href="https://www.instagram.com/figma_dev"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            >
              <i className="fab fa-instagram fw-normal"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/figam-dev-0a99032a0/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            >
              <i className="fab fa-linkedin-in fw-normal"></i>
            </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 className="text-light mb-0">Quick Links</h3>
                    </div>
                    <div className="link-animated d-flex flex-column justify-content-start">
                      <Link className="text-light mb-2" to="/">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Home
                      </Link>
                      <Link className="text-light mb-2" to="/about">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        About Us
                      </Link>



                      {/* <Link className="text-light mb-2" to="/product">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Products
                      </Link> */}

                      
                      
                     
                     
                      
                      <Link className="text-light" to="/contact">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Contact Us
                      </Link>



                
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 className="text-light mb-0">Popular Links</h3>
                    </div>
                    <div className="link-animated d-flex flex-column justify-content-start">
                    
                     
                      <Link className="text-light mb-2" to="/service">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Our Services
                      </Link>
                      <a className="text-light mb-2" to="#teams">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Meet The Team
                      </a>
                      <Link className="text-light mb-2" to="/blog">
                        <i className="bi bi-arrow-right text-primary me-2 white-color"></i>
                        Latest Blog
                      </Link>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid text-white"
          style={{ background: "#061429" }}
        >
          <div className="container text-center">
            <div className="row justify-content-end">
              <div className="col-lg-8 col-md-6">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "75px" }}
                >
                  <p className="mb-0">
                    &copy;{" "}
                    <Link className="text-white border-bottom" to="">
                     Figma Dev
                    </Link>
                    . All Rights Reserved.
                 
              
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
  )
}

export default MyFoter