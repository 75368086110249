import React, { useEffect, useState } from "react";

import firstblog from "../img/blog-1.jpg";

import {  useLocation, useParams } from "react-router-dom";

import '../main.css';


import TopBar from "../Sections/TopBar";
import NavBar from "../Sections/NavBar";
import MyFoter from "../Sections/MyFoter";
import { blogsArray } from "../Sections/Blogsdata";





function BlogDetails() {


  const [blogData, setBlogData] = useState(null);


  const {id} = useParams()

  useEffect(() => {
    // Filter blogsArray based on the id parameter
    const filteredData = blogsArray.filter((blog) => blog.id === parseInt(id));

    // Check if any data is found
    if (filteredData.length > 0) {
      // Set the filtered data in the state
      setBlogData(filteredData[0]);
    }
  }, [id]);

  return (
    <div>
      <TopBar />

      {/* <!-- Navbar Start --> */}
      <div className="container-fluid position-relative p-0">
        <NavBar />

        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Blog Detail
              </h1>
           
            </div>
          </div>
        </div>
      </div>
   

    
     
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-8">
              {/* <!-- Blog Detail Start --> */}
              <div className="mb-5">
                <img
                  className="img-fluid w-100 rounded mb-5"
                  src={blogData?.image}
                  alt=""
                />
                <h1 className="mb-4">{blogData?.primaryHeading}</h1>
                <p >{blogData?.paragraph1}</p>
                <p >{blogData?.paragraph2}</p>
                <p>{blogData?.paragraph3}</p>
                <p>{blogData?.paragraph4}</p>
              </div>
              {/* <!-- Blog Detail End -->     */}

              {/* <!-- Comment List Start --> */}
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="mb-0">{blogData?.comments?.length} Comments</h3>
              </div>

              {blogData?.comments?.map((i, j) => {
                return (
                  <div className="mb-5"    key={`com-${j}`}>
                    <div className="d-flex mb-4">
                      <img
                        src={i.image}
                        alt="user"
                        className="img-fluid rounded"
                        style={{ width: "45px", height: "45px" }}
                      />

                      <div className="ps-3">
                        <h6>
                          {i.name}
                          <small>
                            <i>{i.date}</i>
                          </small>
                        </h6>
                        <p >{i.description}</p>
                        {/* <button className="btn btn-sm btn-light">Reply</button> */}
                      </div>
                    </div>
                  </div>
                );
              })}

 
            </div>

            {/* <!-- Sidebar Start --> */}
            <div className="col-lg-4">
          
              <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                  <h3 className="mb-0">Categories</h3>
                </div>
                <div className="link-animated d-flex flex-column justify-content-start">
                  {blogData?.categories?.map((i, j) => {
                    return (
                      <div
                        className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                        key={`cat-${j}`}
                      >
                        <i className="bi bi-arrow-right me-2"></i>
                        {i}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <!-- Category End --> */}

              {/* <!-- Recent Post Start --> */}
              <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                  <h3 className="mb-0">Recent Post</h3>
                </div>
           {blogsArray?.map((i, j)=>{
            return(
              <div className="d-flex rounded overflow-hidden mb-3" key={`blogs-${j}`} >
              <img
                className="img-fluid"
                src={i.image}
                style={{
                  width: "100px",
                  height: "100px",
                  objectfit: "cover",
                }}
                alt=""
              />
              <div
               
                className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
              >
               {i.primaryHeading}
              </div>
            </div>
            )
           })}
              
              </div>
     
              <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <img src={firstblog} alt="" className="img-fluid rounded" />
              </div>
              {/* <!-- Image End --> */}

              {/* <!-- Tags Start --> */}

              <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                  <h3 className="mb-0">Tag Cloud</h3>
                </div>

                <div className="d-flex flex-wrap m-n1">
                  {blogData?.tags?.map((i, j) => {
                    return (
                      <div   key={`tags-${j}`}  className="btn btn-light m-1">
                        {i}
                      </div>
                    );
                  })}
                </div>
              </div>

            
          
            </div>
            {/* <!-- Sidebar End --> */}
          </div>
        </div>
      </div>
      {/* <!-- Blog End --> */}

      <MyFoter />
    </div>
  );
}

export default BlogDetails;
