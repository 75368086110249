import React from "react";
import { Link } from "react-router-dom";


import carosole from "../img/carousel-1.jpg";
import carosoles from "../img/carousel-2.jpg";


import TopBar from "../Sections/TopBar";
import NavBar from "../Sections/NavBar";
import MyFoter from "../Sections/MyFoter";
import Serivice from "../Sections/Serivice";

import Quote from "../Sections/Quote";
import Testimonial from "../Sections/Testimonial";
import Team from "../Sections/Team";
import Blog from "../Sections/Blog";

import Feature from "../Sections/Feature";
import AboutUs from "../Sections/AboutUs";




// import Vendor from "../Sections/Vendor";

// team images end



function Home() {
  return (
    <div>
      <TopBar />

      {/* <!-- Navbar & Carousel Start --> */}
      <div className="container-fluid position-relative p-0">
        <NavBar />

        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={carosole} alt="carousel" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="#quite-redirect-page"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <Link
                    to="/contact"
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={carosoles} alt="carousel" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "900px" }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Creative & Innovative
                  </h5>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Creative & Innovative Digital Solution
                  </h1>
                  <a
                    href="quote.html"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </a>
                  <Link
                    to=""
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              ariahidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* <!-- Navbar & Carousel End --> */}

      {/* Full Screen Search */}
      <div className="modal fade" id="searchModal" tabIndex="-1">
        <div className="modal-dialog modal-fullscreen">
          <div
            className="modal-content"
            style={{ background: "rgba(9, 30, 62, .7)" }}
          >
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn bg-white btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="input-group" style={{ maxWidth: "600px" }}>
                <input
                  type="text"
                  className="form-control bg-transparent border-primary p-3"
                  placeholder="Type search keyword"
                />
                <button className="btn btn-primary px-4">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Full Screen Search End */}

      {/* Facts */}
      <div className="container-fluid facts py-5 pt-lg-0">
        <div className="container py-5 pt-lg-0">
          <div className="row gx-0">
            {/* Fact 1 */}
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-users text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Happy Clients</h5>
                  <h1 className="text-white mb-0" data-toggle="counter-up">
                    2359
                  </h1>
                </div>
              </div>
            </div>
            {/* Fact 2 */}
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4 bg-yellow"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-check text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">Projects Done</h5>
                  <h1 className="mb-0" data-toggle="counter-up">
                   2450
                  </h1>
                </div>
              </div>
            </div>
            {/* Fact 3 */}
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-award text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Win Awards</h5>
                  <h1 className="text-white mb-0" data-toggle="counter-up">
                   53
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts End */}

      {/* About */}
   <AboutUs/>
      {/* About End */}

      {/* Features */}
   <Feature/>
      {/* Features End */}

      {/* <!-- Service Start --> */}
    <Serivice/>
      {/* <!-- Service End --> */}

      {/* <!-- Pricing Plan Start --> */}
    {/* <Pricing/> */}
      {/* <!-- Pricing Plan End --> */}

      {/* <!-- Quote Start --> */}
      <div id="quite-redirect-page">
     <Quote/>
     </div>
      {/* <!-- Quote End --> */}

      {/* <!-- Testimonial Start --> */}
     <Testimonial/>
      {/* <!-- Testimonial End --> */}

      {/* <!-- Team Start --> */}
   
      {/* <!-- Team End --> */}
      <div id="teams">
<Team/>
</div>
      {/* <!-- Blog Start --> */}
    <Blog/>
      {/* <!-- Blog Start --> */}


      {/* <!-- Vendor Start --> */}
 {/* <Vendor/> */}
      {/* <!-- Vendor End --> */}


<MyFoter/>

   

      {/* <!-- Back to Top --> */}
      {/* <Link
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i class="bi bi-arrow-up"></i>
      </Link> */}
      





    </div>
    
  );
}

export default Home;
