import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import figmalogo from "../img/figmadev-01.png"
// import figmalogotwo from "../img/figmadev-02.png"
// import figmalogothird from "../img/Figmadev-03.png"
import figmalogofive from "../img/figmadev-05.png"
// import figmalogosix from "../img/figmadev-06.png"

function NavBar() {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  



  return (
    <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
    <Link to="/" className="navbar-brand p-0">
  
      <div className='figmalogotext display-none'>
      <img src={figmalogo} alt="" style={{width : '60px', height:'45px'}} />
   
      <h2 className='logocolor' style={{marginTop:'10px',color:'#112F53'}}> Figma Dev</h2>
      </div>
      <div className='scroll___text_logo '>
      <img src={figmalogofive} alt="" style={{width : '60px', height:'45px'}} />
   
      <h2 className='logocolor' style={{color:'#F2BE10', marginTop:'10Px'}} > Figma Dev</h2>
      </div>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span className="fa fa-bars"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav ms-auto py-0">
        <NavLink to="/" className="nav-item nav-link" onClick={scrollToTop}>Home</NavLink>
        <NavLink to="/about" className="nav-item nav-link" onClick={scrollToTop}>About</NavLink>
        <NavLink to="/service" className="nav-item nav-link" onClick={scrollToTop}>Services</NavLink>
        {/* <NavLink to="/product" className="nav-item nav-link" onClick={scrollToTop}>Products</NavLink> */}
        <NavLink to="/blog" className="nav-item nav-link" onClick={scrollToTop}>Blogs</NavLink>

      
      </div>
   
    
      <Link to="/contact" className="btn btn-primary py-2 px-4 ms-3">
      <NavLink to="/contact" className="nav-item nav-link white-color" onClick={scrollToTop}>Contact Us</NavLink>
       {/* Contact Us */}
      </Link>
    </div>
  </nav>
  )
}

export default NavBar