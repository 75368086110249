import React from 'react'
import { Link } from 'react-router-dom'

function Serivice() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5 ">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto "
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase heading-colors ">
          Our Services
        </h5>
        <h1 className="mb-0">
          Custom IT Solutions for Your Successful Business
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-shield-alt text-white"></i>
            </div>
            <h4 className="mb-3 heading-colors">Digital Marketing</h4>
            <p className="m-0 heading-colors">
            Elevate your brand with tailored digital marketing services—SEO, social media, and content strategies.

            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-chart-pie text-white"></i>
            </div>
            <h4 className="mb-3 heading-colors">Ecomerce Solution</h4>
            <p className="m-0 heading-colors">
            Revolutionize your online business: captivating design, ironclad security, and flawless shopping.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-code text-white"></i>
            </div>
            <h4 className="mb-3 heading-colors">Web Development</h4>
            <p className="m-0 heading-colors">
            FigmaDev crafts seamless web experiences: innovation, precision, and excellence in every line of code.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fab fa-android text-white"></i>
            </div>
            <h4 className="mb-3 heading-colors">Apps Development</h4>
            <p className="m-0 heading-colors">
            levating app experiences with innovation and precision. Your vision, our code – excellence delivered.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-search text-white"></i>
            </div>
            <h4 className="mb-3 heading-colors">SEO Optimization</h4>
            <p className="m-0 heading-colors">
              Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
              ipsum dolore sed
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
            <h3 className="text-white mb-3 heading-colors">Call Us For Quote</h3>
            <p className="text-white mb-3 heading-colors">
            Unlock innovation with FigmaDev. Call now for a tailored quote – your digital transformation awaits!
            </p>
            <h2 className="text-white mb-0">+92-323-0002900</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Serivice