import React from 'react'
import aboutimg from "../img/about.jpg";
import { Link } from 'react-router-dom';


function AboutUs() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">
              About Us
            </h5>
            <h1 className="mb-0">
            Crafting the Future of Digital Innovation with FigmaDev
            </h1>
          </div>
          <p className="mb-4">
          At FigmaDev, we are not just a tech company; we are architects of the digital future. With a passion for innovation and a commitment to excellence, we redefine the boundaries of what's possible in the realm of IT. Specializing in Figma development, our team of seasoned professionals merges creativity with cutting-edge technology to bring your ideas to life. From sleek UI/UX designs to robust software solutions, we thrive on transforming concepts into seamless, user-centric experiences. Join us on the journey where every line of code is a brushstroke in the canvas of innovation, and every project is a testament to our unwavering dedication to pushing technological boundaries. FigmaDev: Where Imagination Meets Code.
          </p>
          <div className="row g-0 mb-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3"></i>Award
                Winning
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3"></i>
                Professional Staff
              </h5>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3"></i>24/7
                Support
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3"></i>Fair Prices
              </h5>
            </div>
          </div>
          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: "60px", height: "60px" }}
            >
              <i className="fa fa-phone-alt text-white"></i>
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0">+92-323-0002900</h4>
            </div>
          </div>
          <Link
            to="/contact"
            className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
            data-wow-delay="0.9s"
          >
            Request A Quote
          </Link>
        </div>
        <div className="col-lg-5" style={{ minHeight: "500px" }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src={aboutimg}
              style={{ objectFit: "cover" }}
              alt="About"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AboutUs