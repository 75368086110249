import React from 'react'
import { Link } from 'react-router-dom'

import { blogsArray } from './Blogsdata';



function Blog() {
  function truncateText(text, maxWords) {
    const words = text.split(" ");
    const truncated = words.slice(0, maxWords).join(" ");
    return words.length > maxWords ? `${truncated} ...` : truncated;
  }
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Latest Blog</h5>
        <h1 className="mb-0">
          Read The Latest Articles from Our Blog Post
        </h1>
      </div>
      <div className="row g-5">
      {blogsArray?.map((i) => {
              return (
                <div
                  className="col-md-4 wow slideInUp"
                  data-wow-delay="0.1s"
                  key={`blog-${i.id}`}
                >
                  <div className="blog-item bg-light rounded overflow-hidden">
                    <div className="blog-img position-relative overflow-hidden">
                      <img className="img-fluid blogs-img-size"  src={i.image} alt="" />
                      <div className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                        {i.primaryHeading}
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="d-flex mb-3">
                        <small className="me-3">
                          <i className="far fa-user text-primary me-2"></i>
                          {i.name}
                        </small>
                        <small>
                          <i className="far fa-calendar-alt text-primary me-2"></i>
                          {i.date}
                        </small>
                      </div>
                      <h4 className="mb-3 white-color">{truncateText(i.secondaryHeading, 2)}</h4>
                      <p >{truncateText(i.paragraph1, 10)}</p>
                      <Link
                        className="text-uppercase"
                        to={`/detail/${i.id}`}
                      >
                        Read More <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
     
      </div>
    </div>
  </div>
  )
}

export default Blog