import React from 'react'

function Quote() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Request A Quote
                </h5>
                <h1 className="mb-0">
                  Need A Free Quote? Please Feel Free to Contact Us
                </h1>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-4">
                    <i className="fa fa-reply text-primary me-3"></i>Reply
                    within 24 hours
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-4">
                    <i className="fa fa-phone-alt text-primary me-3"></i>24 hrs
                    telephone support
                  </h5>
                </div>
              </div>
              <p className="mb-4">
              Embark on your digital journey with FigmaDev. Contact us today for a free quote tailored to your needs. Our team of experts is ready to transform your vision into a reality. Let's collaborate and redefine possibilities together. Your success begins with a simple contact. Reach out now!
              </p>
              <div
                className="d-flex align-items-center mt-2 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-phone-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+92-323-0002900</h4>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5">
              <div
                className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <form>
                  <div className="row g-3">
                    <div className="col-xl-12">
                      <input
                        type="text"
                        className="form-control bg-light border-0 bg-white"
                        placeholder="Your Name"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="email"
                        className="form-control bg-light border-0 bg-white "
                        placeholder="Your Email"
                        style={{ height: "55px", backgroundColor:'red !important'}}
                      />
                    </div>
                    <div className="col-12">
                      <select
                        className="form-select bg-light border-0 bg-white"
                        style={{ height: "55px" }}
                      >
                        <option selected>Select A Service</option>
                        <option value="1"> Shopify</option>
                        <option value="2"> Web Development</option>
                        <option value="3"> Mobile App Development</option>
                      </select>
                    </div>
                    <div className="col-12 yellow-color">
                      <textarea
                        className="form-control bg-light border-0 bg-white yellow-color text___area_placeholder"
                        rows="3"
                        placeholder="Message"
                        style={{ color: '#F2BE10', important: 'true' }}

                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-dark w-100 py-3 bg-yelow" type="submit">
                        Request A Quote
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
  )
}

export default Quote