import React from 'react'
import feature from "../img/feature.jpg";



function Feature() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">
          Why Choose Us
        </h5>
        <h1 className="mb-0">
          We Are Here to Grow Your Business Exponentially
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="row g-5">
            {/* Feature 1 */}
            <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-cubes text-white"></i>
              </div>
              <h4>Best In Industry</h4>
              <p className="mb-0">
              Industry leaders, setting benchmarks in innovation, quality, and cutting-edge solutions. Choose us for mastery, not mediocrity. Elevate your digital journey with excellence, consistently delivered.
              </p>
            </div>
            {/* Feature 2 */}
            <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-award text-white"></i>
              </div>
              <h4>Award Winning</h4>
              <p className="mb-0">
              Proudly adorned with awards, our relentless pursuit of excellence sets us apart. Choose an award-winning partner for your digital endeavors.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 wow zoomIn"
          data-wow-delay="0.9s"
          style={{ minHeight: "350px" }}
        >
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.1s"
              src={feature}
              style={{ objectFit: "cover" }}
              alt="Feature"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row g-5">
            {/* Feature 3 */}
            <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-users-cog text-white"></i>
              </div>
              <h4>Professional Staff</h4>
              <p className="mb-0">
              FigmaDev triumphs through dedicated professionals, turning challenges into opportunities with cutting-edge expertise. Choose us for industry-leading professionalism, turning visions into reality.
              </p>
            </div>
            {/* Feature 4 */}
            <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: "60px", height: "60px" }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <h4>24/7 Support</h4>
              <p className="mb-0">
              Your digital journey, our commitment. Enjoy seamless progress with our 24/7 support. Round-the-clock assistance, ensuring your success at any hour
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Feature