import React from 'react';
import { Routes, Route, Link, HashRouter as Router } from 'react-router-dom';



import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';
import BlogGrid from './Components/BlogGrid';

// import Product from './Components/Product';
import BlogDetails from './Components/BlogDetails';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<Services />} />
        <Route path="blog" element={<BlogGrid />} />
        <Route path="/detail/:id" element={<BlogDetails />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route path="product" element={<Product />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
